import { CSSProperties } from 'react';
import { useTranslate } from 'react-admin';
import { Controller, Path } from 'react-hook-form';
import OtpInput from 'react-otp-input';

import { Box, Typography } from '@mui/material';

import { ExposedControllerProps } from '../../components/forms/type';
import { errorMessage } from '../../components/forms/utils';
import { colorTokens } from '../../themes';

type TOTPCodeInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> & {
  disabled: boolean;
  label?: string;
};

const codeInputStyle: CSSProperties = {
  width: '2.75rem',
  height: '4rem',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  fontSize: '2.5rem',
  textAlign: 'center',
  margin: '0.375rem',
  border: `1px solid ${colorTokens.surface.grey}`,
};

const separatorStyle: CSSProperties = {
  marginLeft: '1.5rem',
};

export function TOTPCodeInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({ disabled, name, control, rules }: TOTPCodeInputProps<TFieldValues, TName>) {
  const translate = useTranslate();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState }) => (
        <Box
          sx={{
            margin: '0 2rem',
            textAlign: 'center',
          }}
        >
          <OtpInput
            value={value}
            inputType="tel"
            numInputs={6}
            onChange={onChange}
            shouldAutoFocus
            renderInput={(props, index) =>
              index === 3 ? (
                <input
                  disabled={disabled}
                  {...props}
                  style={{ ...codeInputStyle, ...separatorStyle }}
                />
              ) : (
                <input disabled={disabled} {...props} style={codeInputStyle} />
              )
            }
          />
          {fieldState.error && fieldState.error.type !== 'minLength' && (
            <Typography color="error" sx={{ height: '1.5rem' }}>
              {errorMessage(fieldState.error, translate, undefined, rules) ??
                ''}
            </Typography>
          )}
        </Box>
      )}
    />
  );
}
