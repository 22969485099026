import { Fragment } from 'react';
import {
  BooleanField,
  Datagrid,
  Edit,
  List,
  TextField,
  NumberField,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';

import { CodesField } from '@components/fields';
import { CreateButton } from '@components/generic/CreateButton';
import { EditButton } from '@components/generic/EditButton';
import { Box, Paper } from '@mui/material';

import { CompanyForm } from './form';

const CompanyPlanCreateButton = () => {
  const { id } = useParams<'id'>();
  const record = { company: { id } };

  return (
    <CreateButton
      redirect={`/companies/${id}`}
      record={record}
      ctaText="companyPlan.create"
    />
  );
};

const CompanyPlansActions = () => (
  <TopToolbar>
    <CompanyPlanCreateButton />
  </TopToolbar>
);

const Empty = () => (
  <Box textAlign="center" m={1}>
    <CompanyPlanCreateButton />
  </Box>
);

export const CompanyEdit = () => {
  const location = useLocation();
  const translate = useTranslate();
  const { id } = useParams<'id'>();
  return (
    <Paper sx={{ padding: '1rem' }} elevation={0}>
      <Edit
        resource="companies"
        redirect={location.state?.redirect || 'list'}
        mutationMode="pessimistic"
      >
        <CompanyForm />
      </Edit>
      <List
        resource="company-plans"
        exporter={false}
        pagination={null}
        perPage={50}
        empty={<Empty />}
        filter={{ company: { id } }}
        actions={<CompanyPlansActions />}
        title={<Fragment />}
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField source="id" />
          <TextField label={translate('common.alias')} source="alias" />
          <CodesField label={translate('company.codes')} />
          <BooleanField source="active" label={translate('common.active')} />
          <NumberField
            source="maxUsage"
            label={translate('company.maxUsage')}
          />
          <EditButton />
        </Datagrid>
      </List>
    </Paper>
  );
};
