import { Edit } from 'react-admin';

import { ScenariosForm } from './form';

export const ScenariosEdit = (props) => (
  <Edit
    {...props}
    resource="scenarios"
    redirect="list"
    mutationMode="pessimistic"
  >
    <ScenariosForm />
  </Edit>
);
