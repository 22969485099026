import { Edit } from 'react-admin';
import { useLocation, Location } from 'react-router';

import { CompanyPlanCodeForm } from './form';

interface RedirectLocation extends Location {
  state: { redirect: string };
}

export const CompanyPlanCodeEdit = () => {
  const location = useLocation() as RedirectLocation;
  return (
    <Edit
      resource="company-plan-codes"
      redirect={location?.state?.redirect || 'list'}
      sx={{ padding: '1rem' }}
      mutationMode="pessimistic"
    >
      <CompanyPlanCodeForm />
    </Edit>
  );
};
