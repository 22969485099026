import { useState } from 'react';
import { useNotify } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation } from '@hooks/queryWrappers';

interface Reset {
  email?: string;
}

export const useResetPasswordSendMail = () => {
  const notify = useNotify();
  const { mutateAsync: send } = useMutation<any, any, Reset>(
    ['send_email'],
    (data: Reset) => ({
      method: 'POST',
      url: '/api/auth/send_email',
      data,
    }),
  );
  const sendEmail = async (auth: Reset) => {
    try {
      await send(auth);
      notify('auth.forms.success.email_sent', { type: 'success' });
    } catch {
      notify('auth.forms.errors.invalid_credentials', { type: 'error' });
    }
  };

  return sendEmail;
};

export const useResetPasswordSendSMS = () => {
  return useMutation<any, any, { token: string; id: string }>(
    ['send_sms_reset'],
    (data) => ({
      method: 'POST',
      url: `/api/auth/send_sms_reset`,
      data,
    }),
  );
};

export interface ResetPasswordForm {
  newPassword: string;
  otp: string;
}

export const useResetPasswordResetSteps = () => {
  const notify = useNotify();
  const form = useForm<ResetPasswordForm>();

  const { token, id } = useParams();
  const [oneFAOK, setOneFAOK] = useState<boolean>(false);

  const sendSMS = useResetPasswordSendSMS();
  const navigate = useNavigate();
  const { mutateAsync: finalizeReset } = useMutation<
    any,
    any,
    ResetPasswordForm
  >(
    ['reset_password', id, token],
    ({ otp, newPassword }: ResetPasswordForm) => ({
      method: 'POST',
      url: `/api/auth/reset_password`,
      data: {
        pwdResetCode: otp,
        id,
        token,
        newPassword,
      },
    }),
  );

  const handleSubmitOneFA = form.handleSubmit(async () => {
    try {
      await sendSMS.mutateAsync({ token, id });
      setOneFAOK(true);
    } catch {
      notify('auth.forms.errors.invalid_credentials');
    }
  });

  const handleSubmitTwoFA = form.handleSubmit(async (data) => {
    try {
      await finalizeReset(data);
      notify('auth.forms.success.password_reset');
      navigate('/login');
    } catch {
      notify('auth.forms.errors.invalid_credentials');
    }
  });

  return { handleSubmitOneFA, handleSubmitTwoFA, oneFAOK, form };
};
