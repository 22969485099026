import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';

import { useSocketDiscussionEventHandlers } from '@hooks/discussionEvents';
import { useQuery } from '@hooks/queryWrappers';

import { nestSocketURL } from '../common/url';
import { ALL_PRO_ROOM } from '../pages/subjects';
import { useSelector } from '../store';
import { RoomContentEmitter, Socket, SocketEvents } from '../types/socket';

const GatewayContext = createContext<Socket | null>(null);

export const useGateway = () => {
  return useContext(GatewayContext);
};

const defaultValidity = 15 * 60; // 15 minutes in seconds

export const GatewayProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [ws, setWs] = useState<Socket | null>(null);

  const email = useSelector((state) => state.user.email);
  const dispatch = useDispatch();
  const [onLine, setOnLine] = useState(window.navigator.onLine);
  const [appState, setAppState] = useState<'inactive' | 'active'>(
    window.document.hidden ? 'inactive' : 'active',
  );

  useEffect(() => {
    const dispatchActive = () => {
      setAppState('active');
    };
    const dispatchOnLine = () => {
      setOnLine(true);
    };

    const dispatchOffLine = () => {
      setOnLine(false);
    };
    const dispatchInactive = () => {
      setAppState('inactive');
    };
    const dispatchFromVisibility = () => {
      setAppState(
        [undefined, 'visible', 'prerender'].includes(document.visibilityState)
          ? 'active'
          : 'inactive',
      );
    };
    window.addEventListener('focus', dispatchActive);
    window.addEventListener('blur', dispatchInactive);
    window.addEventListener('visibilitychange', dispatchFromVisibility);
    window.addEventListener('online', dispatchOnLine);
    window.addEventListener('offline', dispatchOffLine);
    return () => {
      window.removeEventListener('focus', dispatchActive);
      window.removeEventListener('blur', dispatchInactive);
      window.removeEventListener('visibilitychange', dispatchFromVisibility);
      window.removeEventListener('online', dispatchOnLine);
      window.removeEventListener('offline', dispatchOffLine);
    };
  }, []);

  const { data } = useQuery<{
    socketExp: number;
    socketJwt: string;
  }>(
    ['getSocketToken'],
    {
      method: 'GET',
      url: '/api/auth/socket_token',
    },
    {
      enabled: Boolean(email),
      refetchInterval(query) {
        return (
          (query.state.data?.socketExp ?? defaultValidity) * (9 / 10) * 1000
        );
      },
    },
  );

  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    if (!onLine) {
      return;
    }
    if (socketRef.current && !ws && appState === 'active') {
      let reconnectionInterval = setInterval(() => {
        socketRef.current?.connect();
      }, 2000);
      return () => clearInterval(reconnectionInterval);
    }
  }, [appState, onLine, ws]);

  useEffect(() => {
    if (ws && data?.socketJwt) {
      ws.emit(SocketEvents.USER_SENT_SOCKET_TOKEN, {
        token: data.socketJwt,
      });
    }
  }, [data?.socketJwt, ws]);

  // we want onLine, email and socket exp concatenated to avoid multiple connection/disconnection.
  //  - onLine ensures browser allows connection
  //  - email ensures we have a user
  //  - socket exp ensures we have a valid token
  const canConnect = Boolean(onLine && email && data?.socketExp);
  useEffect(() => {
    if (!canConnect) {
      return;
    }
    const socket = io(nestSocketURL, {
      withCredentials: true,
      transports: ['polling', 'websocket'],
    }) as Socket;

    socket.roomContentEmitters = {};
    socket.getUsersInRoom = (room) => {
      return socket.roomContentEmitters?.[room]?.getRoomUsers() ?? [];
    };

    socket.onRoomUsersChanged = (
      room: string,
      callback: (users: string[]) => void,
    ) => {
      const alreadyHasEmitter = Boolean(socket.roomContentEmitters[room]);
      if (!alreadyHasEmitter) {
        socket.roomContentEmitters[room] = new RoomContentEmitter();
      }
      const unsubscribe =
        socket.roomContentEmitters[room].addListener(callback);

      if (alreadyHasEmitter) {
        callback(socket.roomContentEmitters[room].getRoomUsers());
      }
      return () => {
        const remaining = unsubscribe();
        if (remaining <= 0) {
          delete socket.roomContentEmitters[room];
        }
      };
    };

    socket.on('disconnect', () => {
      setWs(null);
    });

    // register a room listener for all pro room to store ALL pro users from start
    socket.onRoomUsersChanged(ALL_PRO_ROOM, () => {});

    socket.on(SocketEvents.ROOM_CONNECTED_USERS_CHANGED, ({ room, users }) => {
      socket.roomContentEmitters[room]?.emit(users);
    });
    socket.on('connect', () => {
      socket.emit(SocketEvents.SOCKET_ENTERED_ROOM, { room: ALL_PRO_ROOM });
      setWs(socket);
    });

    socketRef.current = socket;

    return () => {
      if (socket) {
        // closing socket will call setWs(null) in socket.on('disconnect')
        socket.off();
        socket.close();
      } else {
        // if socket is undefined just ensure ws is null
        setWs(null);
      }
      socketRef.current = null;
    };
  }, [dispatch, canConnect, setWs]);

  useSocketDiscussionEventHandlers(ws);

  return (
    <GatewayContext.Provider value={ws}>{children}</GatewayContext.Provider>
  );
};
