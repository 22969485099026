import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { Subject } from '@boTypes/subject';
import { DialogTitle } from '@components/generic/Dialog';
import { useQuery } from '@hooks/queryWrappers';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from '@mui/material';
import { Media } from '@teammay/mayssenger';

import { colorTokens } from '../../../themes';

enum MediaType {
  image = 'image',
  video = 'video',
} // to reflect chat-core MediaType types

class MediaContent {
  id: number;
  type: MediaType;
  content: string;
}

const mediaStyles = (selected: boolean) => ({
  width: '75px',
  height: '75px',
  objectFit: 'contain' as const,
  borderRadius: '0.5rem',
  border: selected ? `2px solid ${colorTokens.content.primary}` : undefined,
  filter: selected ? 'grayscale(0%)' : 'grayscale(75%)',
});

const useMediaList = (subjectId: Subject['id'], modalOpened: boolean) => {
  return useQuery<MediaContent[], any, Media[]>(
    ['mediaList', subjectId],
    () => ({
      method: 'GET',
      url: `/api/discussion_events/mediaForSubject/${subjectId}`,
    }),
    {
      enabled: modalOpened,
      select: (data: MediaContent[]) =>
        data.map((m) => ({
          id: String(m.id),
          uri: m.content,
          type: m.type,
          name: '',
          thumbnail: m.content,
          subjectId,
        })),
    },
  );
};

export const MediaViewer = ({
  open,
  onClose,
  media,
}: {
  open: boolean;
  onClose: () => void;
  media: Media & { subjectId?: Subject['id'] };
}) => {
  const [selectedMedia, setSelectedMedia] = useState(media);
  const { data: mediaList, isLoading } = useMediaList(media?.subjectId, open);
  const resetTransformRef = useRef(null);
  useEffect(() => {
    if (mediaList && media?.id) {
      setSelectedMedia(mediaList.find((m) => m.id === media.id) ?? null);
    }
  }, [mediaList, media?.id]);
  useEffect(() => {
    if (resetTransformRef.current) {
      resetTransformRef.current();
    }
  }, [selectedMedia]);
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (selectedMedia && mediaList) {
        const index = mediaList.findIndex((m) => m.id === selectedMedia.id);
        if (e.key === 'ArrowLeft') {
          setSelectedMedia(
            mediaList[index - 1] ?? mediaList[mediaList.length - 1],
          );
        } else if (e.key === 'ArrowRight') {
          setSelectedMedia(mediaList[index + 1] ?? mediaList[0]);
        }
      }
    },
    [selectedMedia, mediaList],
  );
  useEffect(() => {
    // add key event listener to navigate through media (left / right)
    open && document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [open, handleKeyPress]);

  const translate = useTranslate();
  return (
    <Dialog open={open} onClose={onClose} fullScreen fullWidth>
      <DialogTitle onClose={onClose}>{translate('common.media')}</DialogTitle>
      <DialogContent
        sx={{ position: 'relative', padding: 0, overflow: 'hidden' }}
      >
        {selectedMedia?.type === MediaType.image && (
          <TransformWrapper
            centerOnInit
            maxScale={10}
            wheel={{ step: 3 }}
            pinch={{ step: 3 }}
          >
            {({ resetTransform }) => {
              resetTransformRef.current = resetTransform;
              return (
                <>
                  <Button
                    variant="contained"
                    onClick={() => resetTransform()}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: '1.5rem',
                      zIndex: 5,
                    }}
                  >
                    Reset
                  </Button>
                  <TransformComponent>
                    <img
                      style={{
                        width: 'calc(100vw)',
                        height: `calc(100vh - (4.5rem + 75px + 1rem ))`,
                        objectFit: 'contain',
                      }}
                      src={selectedMedia.uri}
                    />
                  </TransformComponent>
                </>
              );
            }}
          </TransformWrapper>
        )}
        {selectedMedia?.type === MediaType.video && (
          <video
            controls
            style={{
              width: 'calc(100vw - 1rem)',
              height: `calc(100vh - (4.5rem + 75px + 1.375rem ))`,
              objectFit: 'contain',
            }}
          >
            <source src={selectedMedia.uri} />
            <source src={selectedMedia.uri} type="video/mp4" />
            Your browser does not support this video format.
          </video>
        )}
        <Box
          sx={{
            height: '91px',
            backgroundColor: colorTokens.surface.subtle,
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                padding: '0.5rem',
                display: 'flex',
                overflowX: 'auto',
                flexDirection: 'row',
                gap: '0.25rem',
              }}
            >
              {mediaList?.map((m) => {
                if (m.type === MediaType.image) {
                  return (
                    <img
                      onClick={() => setSelectedMedia(m)}
                      key={m.id}
                      style={mediaStyles(selectedMedia?.id === m.id)}
                      src={m.uri}
                    />
                  );
                } else if (m.type === MediaType.video) {
                  return (
                    <video
                      onClick={() => setSelectedMedia(m)}
                      key={m.id}
                      style={mediaStyles(selectedMedia?.id === m.id)}
                    >
                      <source src={m.uri} />
                      <source src={m.uri} type="video/mp4" />
                    </video>
                  );
                }
              })}
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
