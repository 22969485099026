import { Edit } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { PregnanciesForm } from './form';

export const PregnanciesEdit = () => {
  const location = useLocation();

  return (
    <Edit
      resource="pregnancies"
      redirect={location?.state?.redirect || 'list'}
      mutationMode="pessimistic"
    >
      <PregnanciesForm />
    </Edit>
  );
};
