import { CSSProperties } from 'react';
import { useTranslate } from 'react-admin';
import { Controller, Path } from 'react-hook-form';
import OtpInput from 'react-otp-input';

import { Box, Typography } from '@mui/material';

import { ExposedControllerProps } from '../../components/forms/type';
import { errorMessage } from '../../components/forms/utils';
import { colorTokens } from '../../themes';

type OTPCodeInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> & {
  disabled: boolean;
  label?: string;
  numInputs?: number;
  onPaste?: (event: React.ClipboardEvent<HTMLDivElement>) => void;
};

const codeInputStyle: CSSProperties = {
  width: '2.75rem',
  height: '4rem',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  fontSize: '2.5rem',
  textAlign: 'center',
  margin: '0.375rem',
  border: `1px solid ${colorTokens.surface.grey}`,
};

export function OTPCodeInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  disabled,
  name,
  control,
  rules,
  numInputs,
  onPaste,
}: OTPCodeInputProps<TFieldValues, TName>) {
  const translate = useTranslate();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState }) => (
        <Box
          sx={{
            margin: '0 2rem',
            textAlign: 'center',
          }}
        >
          <OtpInput
            value={value}
            inputType="tel"
            numInputs={numInputs ?? 4}
            onChange={onChange}
            shouldAutoFocus
            renderInput={(props) => (
              <input disabled={disabled} {...props} style={codeInputStyle} />
            )}
            onPaste={onPaste}
          />
          {fieldState.error && fieldState.error.type !== 'minLength' && (
            <Typography color="error" sx={{ height: '1.5rem' }}>
              {errorMessage(fieldState.error, translate, undefined, rules) ??
                ''}
            </Typography>
          )}
        </Box>
      )}
    />
  );
}
