import { useEffect } from 'react';
import { useGetIdentity, useTranslate } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { AuthCard } from './components/authCard';
import { useLoginSteps } from './login/hooks';
import { OneFAForm } from './OneFAForm';
import { TwoFA } from './TwoFA';
import { useSelector } from '../store';

export const Login = () => {
  const translate = useTranslate();
  const {
    form,
    oneFAOK,
    forceSms,
    twoFAType,
    handleOneFASubmit,
    handleLogin2FA,
    handleSwitchSms,
  } = useLoginSteps();

  // Redirect to home if the user is already logged in
  const navigate = useNavigate();
  const { email } = useSelector((state) => state.user);
  const identity = useGetIdentity();
  useEffect(() => {
    if (email && identity?.data?.email === email) {
      navigate('/');
    }
  }, [email, identity?.data?.email, navigate]);

  return (
    <FormProvider {...form}>
      <AuthCard
        hint={
          oneFAOK
            ? translate(
                forceSms || twoFAType === 'sms'
                  ? 'auth.login.2FA'
                  : 'auth.login.TOTP',
              )
            : ''
        }
      >
        {!oneFAOK ? (
          <OneFAForm onSubmit={handleOneFASubmit} />
        ) : (
          <TwoFA
            onSubmit={handleLogin2FA}
            withTrusted={true}
            type={forceSms ? 'sms' : twoFAType}
            onToggleSms={twoFAType === 'totp' ? handleSwitchSms : undefined}
          />
        )}
      </AuthCard>
    </FormProvider>
  );
};
