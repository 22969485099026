import { AuthCard } from './components/authCard';
import { ResetForm } from './reset/ResetForm';

export const ResetScreen = () => {
  return (
    <AuthCard>
      <ResetForm />
    </AuthCard>
  );
};
