import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Box, CardActions, CircularProgress, Typography } from '@mui/material';

import { OTPCodeInput } from './components/SMSCodeInput';
import { AuthFormContainer } from './components/styled';
import { TOTPCodeInput } from './components/TOTPCodeInput';
import { TwoFAFormValues } from './login/hooks';
import { CheckboxInput } from '../components/forms/checkBoxInput';
import { Button } from '../components/generic/Button';

const OTPNumInputs = 4;

export const TwoFA = ({
  onSubmit,
  withTrusted = false,
  type = 'sms',
  onToggleSms,
}: {
  onSubmit: () => void;
  withTrusted?: boolean;
  type?: 'sms' | 'totp';
  onToggleSms?: () => void;
}) => {
  const { control, formState } = useFormContext<TwoFAFormValues>();
  const { isSubmitting, isValid } = formState;
  const translate = useTranslate();

  return (
    <form onSubmit={onSubmit}>
      <AuthFormContainer>
        {type === 'sms' ? (
          <OTPCodeInput
            name="otp"
            control={control}
            rules={{ required: true, minLength: 4 }}
            disabled={isSubmitting}
            numInputs={OTPNumInputs}
            onPaste={() => {
              onSubmit();
            }}
          />
        ) : (
          <TOTPCodeInput
            name="otp"
            control={control}
            rules={{ required: true, minLength: 6 }}
            disabled={isSubmitting}
          />
        )}
        {onToggleSms && (
          <Typography
            variant="body1"
            align="center"
            onClick={onToggleSms}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              marginBottom: 2,
            }}
          >
            {isSubmitting ? (
              <CircularProgress />
            ) : (
              translate(
                type === 'sms' ? 'auth.login.forceTOTP' : 'auth.login.forceSms',
              )
            )}
          </Typography>
        )}
        {withTrusted && (
          <Box>
            <CheckboxInput
              name="trustedDevice"
              control={control}
              disabled={isSubmitting}
              label={translate('auth.trustDevice')}
            />
          </Box>
        )}
        <CardActions
          sx={{
            padding: 0,
            marginTop: '1rem',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
            value={translate('common.validate')}
            fullWidth
            type="submit"
          />
        </CardActions>
      </AuthFormContainer>
    </form>
  );
};
