import { Edit } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { PricingProGenericForm } from './form';

export const PricingProGenericEdit = () => {
  const location = useLocation();
  return (
    <Edit
      resource="pricing-pro-generic"
      redirect={location?.state?.redirect || '/pricing-pro'}
      mutationMode="pessimistic"
    >
      <PricingProGenericForm />
    </Edit>
  );
};
