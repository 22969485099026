import { useTranslate } from 'react-admin';
import { FormProvider } from 'react-hook-form';

import { AuthCard } from '../components/authCard';
import { TwoFA } from '../TwoFA';
import { useResetPasswordResetSteps } from './hook';
import { ResetPasswordForm } from './ResetPasswordForm';

export const ResetPassword = () => {
  const translate = useTranslate();
  const { form, oneFAOK, handleSubmitOneFA, handleSubmitTwoFA } =
    useResetPasswordResetSteps();

  return (
    <FormProvider {...form}>
      <AuthCard
        hint={
          oneFAOK
            ? translate('auth.resetPassword.twoFA')
            : translate('auth.resetPassword.resetPassword')
        }
      >
        {oneFAOK ? (
          <TwoFA onSubmit={handleSubmitTwoFA} />
        ) : (
          <ResetPasswordForm onSubmit={handleSubmitOneFA} />
        )}
      </AuthCard>
    </FormProvider>
  );
};
