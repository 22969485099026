import { Edit } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { PricingProCustomForm } from './form';

export const PricingProCustomEdit = () => {
  const location = useLocation();
  return (
    <Edit
      resource="pricing-pro-custom"
      redirect={location?.state?.redirect || '/pricing-pro'}
      mutationMode="pessimistic"
    >
      <PricingProCustomForm />
    </Edit>
  );
};
