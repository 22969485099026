import { Translate } from 'react-admin';
import { FieldError } from 'react-hook-form';

export const errorMessage = (
  error: FieldError,
  translate: Translate,
  reportRequired = true,
  rules?: Record<string, any>,
): string | undefined => {
  if (!error) {
    return;
  }

  if (error.message) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  switch (error.type) {
    case 'pattern':
      return translate('forms.errors.invalid_pattern');
    case 'required':
      return reportRequired
        ? translate('forms.errors.required_field')
        : undefined;
    case 'minLength':
      return translate('forms.errors.min_length', {
        minLength: rules?.minLength?.toString(),
      });
    case 'maxLength':
      return translate('forms.errors.max_length', {
        maxLength: rules?.maxLength?.toString(),
      });
    default:
      return translate('forms.errors.generic_error');
  }
};
