import base32Encode from 'base32-encode';
import { fromByteArray, toByteArray } from 'base64-js';
import { useRef, useState } from 'react';
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import QrCode from 'react-qr-code';

import { DialogTitle } from '@components/generic/Dialog';
import { useMutation } from '@hooks/queryWrappers';
import { Visibility } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import { TOTPCodeInput } from '../../auth/components/TOTPCodeInput';
import { useSelector } from '../../store';

type TOTPForm = {
  totp: string;
  secret: string;
};

const issuer = encodeURIComponent('may-BO');

const TOTPDialog = ({
  open,
  onClose,
  OTPSecret,
  onSubmit,
  secretBytes,
}: {
  open: boolean;
  onClose: () => void;
  OTPSecret: string;
  onSubmit?: (data: TOTPForm) => Promise<void>;
  secretBytes: Uint8Array;
}) => {
  const translate = useTranslate();
  const email = useSelector((state) => state.user.email);
  const { control, formState, handleSubmit } = useForm<TOTPForm>({
    defaultValues: {
      totp: '',
      secret: fromByteArray(secretBytes),
    },
  });
  const { isSubmitting, isValid } = formState;
  const submit = handleSubmit(async (data) => {
    await onSubmit(data);
    onClose();
  });
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('auth.totp.title')}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography p={1}>{translate('auth.totp.description')}</Typography>
          <QrCode
            style={{ alignSelf: 'center' }}
            value={`otpauth://totp/${issuer}:${encodeURIComponent(email)}?secret=${OTPSecret}&issuer=${issuer}&digits=6`}
          />
          <Stack flexDirection="row" alignItems="center">
            <Box
              sx={{ flex: 1, backgroundColor: 'background.grey', height: 2 }}
            />
            <Typography alignSelf="center" p={2}>
              {translate('help.or')}
            </Typography>
            <Box
              sx={{ flex: 1, backgroundColor: 'background.grey', height: 2 }}
            />
          </Stack>
          <Typography>
            {translate('auth.totp.manual', {
              code: OTPSecret,
            })}
          </Typography>
        </Box>
        {onSubmit && (
          <Box>
            <Typography>{translate('auth.totp.validate')}</Typography>
            <TOTPCodeInput
              name="totp"
              control={control}
              rules={{ required: true, minLength: 6, maxLength: 6 }}
              disabled={false}
            />
            <Stack flexDirection="row" gap={2} justifyContent="space-around">
              <Button
                disabled={isSubmitting || !isValid}
                onClick={submit}
                variant="contained"
              >
                {isSubmitting ? (
                  <CircularProgress size={16} />
                ) : (
                  translate('common.save')
                )}
              </Button>
              <Button onClick={() => onClose()}>
                {translate('common.cancel')}
              </Button>
            </Stack>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export const TOTPInput = ({
  source,
  label,
}: {
  source: string;
  label: string;
}) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const randomSecretBytes = useRef(
    self.crypto.getRandomValues(new Uint8Array(10)),
  ).current;

  const { mutateAsync } = useMutation<unknown, unknown, TOTPForm>(
    ['updateTotp'],
    (data: TOTPForm) => ({
      method: 'POST',
      url: '/api/auth/totp_secret',
      data,
    }),
    {
      onSuccess: () => {
        notify('generic.updateSuccess');
        refresh();
      },
    },
  );

  const { mutateAsync: removeTotp, isPending: isRemovingTotp } = useMutation<
    unknown,
    unknown,
    void
  >(
    ['removeTotp'],
    () => ({
      method: 'PUT',
      url: '/api/auth/totp/remove',
    }),
    {
      onSuccess: () => {
        notify('generic.updateSuccess');
        refresh();
      },
    },
  );

  if (!record) {
    return null;
  }

  if (record[source]) {
    return (
      <>
        <Typography>
          {label}
          <IconButton onClick={() => setIsDialogOpen(true)}>
            <Visibility />
          </IconButton>
          <IconButton onClick={() => removeTotp()} disabled={isRemovingTotp}>
            <Delete />
          </IconButton>
        </Typography>
        <TOTPDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          OTPSecret={base32Encode(toByteArray(record[source]), 'RFC3548')}
          secretBytes={toByteArray(record[source])}
        />
      </>
    );
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Typography>{label}:</Typography>
      <Button
        variant="outlined"
        onClick={() => setIsDialogOpen(true)}
        sx={{ mx: 2 }}
      >
        {translate('auth.totp.generate')}
      </Button>
      <TOTPDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        OTPSecret={base32Encode(randomSecretBytes, 'RFC3548')}
        secretBytes={randomSecretBytes}
        onSubmit={async (data) => {
          await mutateAsync(data);
        }}
      />
    </Box>
  );
};
