import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Link, Typography } from '@mui/material';

import { ResetPasswordForm as ResetPasswordFormType } from './hook';
import { TextInput } from '../../components/forms/textInput';
import { Button } from '../../components/generic/Button';
import { AuthFormContainer } from '../components/styled';

export const ResetPasswordForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const translate = useTranslate();
  const { control, formState } = useFormContext<ResetPasswordFormType>();
  const { isSubmitting } = formState;

  return (
    <>
      <AuthFormContainer>
        <TextInput
          autoFocus
          control={control}
          name="newPassword"
          type="password"
          label={translate('auth.resetPassword.newPassword')}
          helperText={translate('auth.passwordConstraints')}
          rules={{
            required: true,
            minLength: 8,
            pattern: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          loading={isSubmitting}
          onClick={onSubmit}
          value={translate('auth.resetPassword.validateNew')}
          fullWidth
          sx={{ marginTop: '2rem' }}
        />
      </AuthFormContainer>
      <Typography sx={{ textAlign: 'center', margin: '0.25rem' }}>
        <Link
          href="/#/login"
          sx={{
            fontSize: '0.75rem',
            cursor: 'pointer',
          }}
          underline="hover"
        >
          {translate('auth.backToLogin')}
        </Link>
      </Typography>
    </>
  );
};
