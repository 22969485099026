import { Edit, EditProps } from 'react-admin';

import { CategoriesForm } from './form';

export const CategoriesEdit = (props: Omit<EditProps, 'children'>) => (
  <Edit
    {...props}
    resource="categories"
    redirect="list"
    mutationMode="pessimistic"
  >
    <CategoriesForm />
  </Edit>
);
