import React, { useMemo } from 'react';
import { Link as RouterLink, LinkProps, Location } from 'react-router-dom';

import {
  Button as Btn,
  ButtonProps as BtnProps,
  CircularProgress,
} from '@mui/material';

interface ButtonProps extends BtnProps {
  loading?: boolean;
  label?: string;
  to?: string | Partial<Location>;
  state?: { redirect: string };
}

const CustomLink = (to: string | Partial<Location>) =>
  React.forwardRef<
    HTMLAnchorElement,
    Omit<LinkProps, 'to'> & { to: string | Partial<Location> }
  >(function Link({ ...linkProps }, ref: React.Ref<HTMLAnchorElement>) {
    const pathname =
      typeof to === 'string' || to instanceof String ? to : to.pathname;
    const state =
      typeof to === 'string' || to instanceof String ? undefined : to.state;
    return <RouterLink ref={ref} to={pathname} state={state} {...linkProps} />;
  });

const LinkedButton = (props: ButtonProps) => {
  const { loading = false, to, value, children } = props;

  const LinkComponent = useMemo(() => CustomLink(to), [to]);

  return (
    <Btn {...props} LinkComponent={LinkComponent} disabled={loading}>
      {loading ? (
        <CircularProgress size={25} thickness={2} />
      ) : (
        value || children
      )}
    </Btn>
  );
};

export const Button = (props: ButtonProps) => {
  const { loading = false, to, value, children, ...params } = props;
  if (to) {
    return <LinkedButton {...props} />;
  }
  return (
    <Btn {...params} disabled={loading || props.disabled}>
      {loading ? (
        <CircularProgress size={25} thickness={2} />
      ) : (
        value || children
      )}
    </Btn>
  );
};
