import { JobTitle, Roles } from '@boTypes/user';
import * as Sentry from '@sentry/browser';

import { logoutUserInTracking, setUserInTracking } from '../analytics';
import { TwoFAFormValues } from '../auth/login/hooks';
import { store } from '../store';
import { setUser, setIdentity, setRoles, reset } from '../store/user';
import { apiRequest } from '../utils/httpClients';

export const authProvider = {
  login: async ({
    email,
    password,
    otp: twoFa,
    trustedDevice = false,
    forceSms = false,
  }: TwoFAFormValues) => {
    return apiRequest<{
      user: { staffUser: number; roles: Roles[]; email: string };
      exp: number;
    }>({
      method: 'POST',
      url: '/api/auth/login',
      data: { email, password, otp: twoFa, trustedDevice, forceSms },
    }).then(({ user: { staffUser, roles, email: emailSanitized } }) => {
      setUserInTracking({ id: staffUser, email: emailSanitized });
      Sentry.setUser({ email: emailSanitized, id: String(staffUser) });
      store.dispatch(setUser({ email: emailSanitized, userId: staffUser }));
      store.dispatch(setRoles({ roles }));
    });
  },
  logout: async () => {
    if (store.getState().user.userId) {
      return apiRequest({
        method: 'POST',
        url: '/api/auth/logout',
      })
        .then(() => '/login')
        .catch(() => '/login')
        .finally(() => {
          logoutUserInTracking();
          store.dispatch(reset());
          Sentry.getCurrentScope().setUser(null);
        });
    }
  },
  checkError: async (error) => {
    const REJECT_ON_HTTP_CODES_LIST = [401, 403];
    return REJECT_ON_HTTP_CODES_LIST.includes(error.status)
      ? Promise.reject()
      : Promise.resolve();
  },
  checkAuth: () => {
    const state = store.getState();
    return state.user.userId ? Promise.resolve() : Promise.reject();
  },
  getIdentity: () =>
    apiRequest<{
      id: number;
      roles: Roles[];
      jobTitle: JobTitle;
      firstName: string;
      lastName: string;
      fullName: string;
      email: string;
      phone: string;
      avatar: string;
      iban: string | null;
      bic: string | null;
      language: string;
    }>({
      url: '/api/profiles/me',
    }).then(
      (response) => {
        const {
          id,
          email,
          roles,
          jobTitle,
          firstName,
          lastName,
          fullName,
          language,
        } = response;
        store.dispatch(setRoles({ roles }));
        store.dispatch(
          setIdentity({ firstName, lastName, jobTitle, language }),
        );
        setUserInTracking({
          id,
          email,
          fullName,
          jobTitle,
        });
        return response;
      },
      () => {
        authProvider.logout();
        logoutUserInTracking();
        throw new Error('Unauthorized');
      },
    ),

  // authorization
  getPermissions: () => {
    const state = store.getState();
    return Promise.resolve(state.user.roles);
  },
};
