import { Edit } from 'react-admin';

import { CategoriesForm } from './form';
import useTransform from '../../hooks/transform';

export const ConsultationCategoriesEdit = (props) => {
  const { transform } = useTransform('consultation-categories', 'image');

  return (
    <Edit
      {...props}
      transform={transform}
      resource="consultation-categories"
      redirect="list"
      mutationMode="pessimistic"
    >
      <CategoriesForm />
    </Edit>
  );
};
