import {
  DiscussionEvent,
  RawDiscussionEvent,
  SeenBy,
} from '@boTypes/discussionEvent';
import { Subject } from '@boTypes/subject';
import {
  ContentType,
  IFormMessage,
  ILiviMessage,
  IMediaMessage,
  ISystemMessage,
  ITextMessage,
  MayParticipant,
  SeenData,
} from '@teammay/mayssenger';
import { IContentMessage } from '@teammay/mayssenger/lib/typescript/src/components/ContentMessage';
import { IIntroMessage } from '@teammay/mayssenger/lib/typescript/src/components/IntroMessage';

import { ChatData } from '../types/messages';

const eventToContentTypeMap = {
  guide: ContentType.guide,
  post: ContentType.article,
  masterclass: ContentType.masterclass,
  dailyTip: ContentType.dailyTip,
  weeklyTip: ContentType.weeklyTip,
  folder: ContentType.folder,
} as const;

const formatSeenBy = (seenBy: Array<SeenBy>): Array<SeenData> =>
  seenBy?.map(({ appUserId, date }) => ({
    participantId: `${appUserId}`,
    date,
  })) ?? [];

const liviFromEvent = (event: RawDiscussionEvent): ILiviMessage => ({
  participantId: event.authorEmail,
  messageId: event.id,
  date: event.createdAt,
  type: 'livi',
  seenBy: formatSeenBy(event.seenBy),
});

const contentFromEvent = (event: RawDiscussionEvent): IContentMessage => ({
  participantId: event.authorEmail,
  messageId: event.id,
  date: event.createdAt,
  type: 'content',
  contentType:
    eventToContentTypeMap[event.type as keyof typeof eventToContentTypeMap],
  contentId: event.content,
  seenBy: formatSeenBy(event.seenBy),
});

const textFromEvent = (event: RawDiscussionEvent): ITextMessage => ({
  participantId: event.authorEmail,
  messageId: event.id,
  date: event.createdAt,
  type: 'text',
  text: event.content,
  seenBy: formatSeenBy(event.seenBy),
});

const mediaFromEvent = (
  event: RawDiscussionEvent,
): IMediaMessage & { subjectId?: Subject['id'] } => ({
  participantId: event.authorEmail,
  messageId: event.id,
  date: event.createdAt,
  type: 'media',
  seenBy: formatSeenBy(event.seenBy),
  media: [
    {
      id: event.id,
      type: event.type as 'image' | 'video',
      uri: event.content,
      name: '',
      thumbnail: event.content, // Video thumbnails are retrieved asynchronously in the component
    },
  ],
  subjectId: event.subjectId,
});

const introFromEvent = (event: RawDiscussionEvent): IIntroMessage => ({
  participantId: 'system',
  messageId: event.id,
  date: event.createdAt,
  seenBy: formatSeenBy(event.seenBy),
  text: event.content,
  type: 'intro',
});

const formFromEvent = (event: RawDiscussionEvent): IFormMessage => ({
  participantId: event.authorEmail,
  messageId: event.id,
  date: event.createdAt,
  seenBy: formatSeenBy(event.seenBy),
  type: 'form',
  form: event.form!,
  formAnswers: event.formAnswers,
  pressable: !!event.formAnswers?.locked,
});

const systemFromEvent = (event: RawDiscussionEvent): ISystemMessage | [] =>
  event.content === 'END_SUBJECT'
    ? {
        participantId: 'system',
        messageId: event.id,
        date: event.createdAt,
        seenBy: formatSeenBy(event.seenBy),
        type: 'system',
        text: 'Fin de la question',
      }
    : [];

const typeFormattingMap = {
  partner: liviFromEvent,
  post: contentFromEvent,
  guide: contentFromEvent,
  masterclass: contentFromEvent,
  dailyTip: contentFromEvent,
  weeklyTip: contentFromEvent,
  folder: contentFromEvent,
  text: textFromEvent,
  image: mediaFromEvent,
  video: mediaFromEvent,
  intro: introFromEvent,
  form: formFromEvent,
  system: systemFromEvent,
} as const;

export const formatMessages = (
  rawMessages: Array<DiscussionEvent>,
): ChatData => {
  const knownTypes = Object.keys(typeFormattingMap);

  let participants: Record<string, MayParticipant> = {};

  const messages =
    rawMessages?.length > 0
      ? rawMessages.flatMap((message) => {
          if (!knownTypes.includes(message.type)) {
            return [] as never;
          }

          const participantId = message.authorEmail;
          if (!participants[participantId]) {
            participants[participantId] = {
              participantId,
              name: message.authorName || '',
              avatar: message.authorAvatar || null,
              isMay: message.isMay,
            };
          }

          return typeFormattingMap[message.type](message);
        })
      : [];

  return { messages, participants };
};
