import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { Link, Typography } from '@mui/material';

import { AuthFormContainer } from './components/styled';
import { OneFAFormValues } from './login/hooks';
import { TextInput } from '../components/forms/textInput';
import { Button } from '../components/generic/Button';

export const OneFAForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { control, formState } = useFormContext<OneFAFormValues>();
  const { isSubmitting, isValid } = formState;

  return (
    <>
      <form onSubmit={onSubmit}>
        <AuthFormContainer>
          <TextInput
            control={control}
            name="email"
            label={translate('auth.email')}
            rules={{ required: true, pattern: /.+@.+\..+/ }}
            autoComplete="email"
            color="success"
          />
          <TextInput
            sx={{ marginTop: '1em' }}
            control={control}
            name={'password'}
            label={translate('ra.auth.password')}
            rules={{ required: true, minLength: 8 }}
            type="password"
            autoComplete="current-password"
            color="success"
          />
          <Button
            variant="contained"
            color="primary"
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
            value={translate('auth.login.connect')}
            fullWidth
            type="submit"
            sx={{ marginTop: '2rem' }}
          />
        </AuthFormContainer>
      </form>
      <Typography sx={{ textAlign: 'center', margin: '0.25rem' }}>
        <Link
          onClick={() => navigate('/reset-password')}
          sx={{
            fontSize: '0.75rem',
            cursor: 'pointer',
          }}
          underline="hover"
        >
          {translate('auth.forgottenPassword')}
        </Link>
      </Typography>
    </>
  );
};
