import { useCallback, useState } from 'react';
import { useAuthProvider, useNotify } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useMutation } from '@hooks/queryWrappers';

export interface OneFAFormValues {
  email?: string;
  password?: string;
  forceSms?: boolean;
}

export interface TwoFAFormValues extends OneFAFormValues {
  otp?: string;
  trustedDevice?: boolean;
}

const useSendSMS = () => {
  return useMutation<
    { success: true; type: 'totp' | 'sms' },
    any,
    OneFAFormValues
  >(['send_sms'], (data: OneFAFormValues) => ({
    method: 'POST',
    url: '/api/auth/send_sms',
    data,
  }));
};

const useLogin = () => {
  const authProvider = useAuthProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  return useCallback(
    (params: TwoFAFormValues = {}) =>
      authProvider
        ? authProvider
            .login(params)
            .then((ret) => {
              navigate('/');
              return ret;
            })
            .catch(() => {
              notify('auth.forms.errors.invalid_2FA', {
                type: 'error',
              });
            })
        : Promise.reject(new Error('No authProvider defined')),
    [authProvider, navigate, notify],
  );
};

export const useLoginSteps = () => {
  const { mutateAsync: sendSms } = useSendSMS();
  const [twoFAType, setTwoFAType] = useState<'sms' | 'totp'>('sms');

  const [oneFAOK, setOneFAOK] = useState<boolean>(false);
  const form = useForm<TwoFAFormValues>({
    defaultValues: {
      email: '',
      password: '',
      otp: '',
      forceSms: false,
      trustedDevice: false,
    },
  });

  const forceSms = useWatch({ control: form.control, name: 'forceSms' });
  const notify = useNotify();
  const handleOneFASubmit = form.handleSubmit(async (data: OneFAFormValues) => {
    const result = await Promise.race<
      | { success: true; type: 'totp' | 'sms' }
      | { success: false; error: string }
    >([
      sendSms(data)
        .then((res) => res)
        .catch(() => ({
          success: false as const,
          error: 'auth.forms.errors.invalid_credentials',
        })),
      new Promise((resolve) => {
        setTimeout(
          () =>
            resolve({
              success: false as const,
              error: 'auth.forms.errors.timeout',
            }),
          5000,
        );
      }),
    ]);
    if (result.success) {
      setOneFAOK(true);
      setTwoFAType(result.type);
    } else {
      notify('auth.forms.errors.invalid_credentials', { type: 'error' });
    }
  });

  const login = useLogin();
  const handleLogin2FA = form.handleSubmit(async (data: TwoFAFormValues) => {
    await login(data);
  });

  const switchSMS = async (data: OneFAFormValues) => {
    if (!data.forceSms) {
      await sendSms(
        { ...data, forceSms: true },
        {
          onSuccess: () => {
            form.setValue('forceSms', true);
          },
        },
      );
    } else {
      form.setValue('forceSms', false);
    }
    form.setValue('otp', '');
    form.clearErrors();
  };
  const handleSwitchSms = form.handleSubmit(
    ({ otp, trustedDevice, ...data }) => switchSMS(data),
    async () => {
      const { otp, trustedDevice, ...data } = form.getValues();
      await switchSMS(data);
    },
  );

  return {
    form,
    oneFAOK,
    forceSms,
    twoFAType,
    handleOneFASubmit,
    handleLogin2FA,
    handleSwitchSms,
  };
};
